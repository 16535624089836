@use "sass:color";
@import '../../mixins.scss';


.header {
  display: block;
  background: rgba(0,0,0,0.5);
  text-align: center;
  border-bottom: solid 1px rgba(255,255,255,0.15);
  width: 100%;
}

.panel {
  border: solid 1px var(--base-color);
  width: 90%;
  aspect-ratio: 146/115;
  max-height: 291px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // background: linear-gradient(219deg, rgba(0,0,0,0.5) 50%, rgba(60,60,60,0.6) 100%), repeat center/70% url('../../assets/img/hexGrid1kx1k.png');
  margin-bottom: 20px;
  &:hover {
    border-width: 2px;
  }
  @for-breakpoint('md'){
    // width: 40%;
    // height: 40%;
    // padding-bottom: 20px;
    // background: linear-gradient(219deg, rgba(0,0,0,0.5) 50%, rgba(60,60,60,0.6) 100%), repeat center/50% url('../../assets/img/hexGrid1kx1k.png');

  }
}

.panelIcon {
  width: calc(30% * var(--icon-scale));
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    filter: drop-shadow(10px 10px 5px var(--shadow-color));
  }

}


.InvestorKit {
  margin-top: 30px;
}