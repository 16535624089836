.SignupForm {
  padding-top: 25vh;
  width: 25vw;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
}

.fieldsWrapper {
  padding: 10px 20px 0 0;
  > div {
    width: 100%;
    margin-bottom: 20px;
    div {
      background: #fff;
    }
    input {
      background: #fff;
      width: 100%;
      &:focus-visible {
        outline: none;
        border: solid 1px var(--mui-palette-UltraPlush-cyan);
      }
    }
    label[data-shrink="true"] {
      color: var(--mui-palette-UltraPlush-cyan);
      margin-top: -7px;
      margin-left: -9px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  
  
}