@use "sass:math";
@import '../../mixins.scss';

.MobileNavHeader {
  display: grid;
  grid-template-columns: 1fr 70% 1fr;
  height: var(--mui-shape-mobileNav-height);
  width: 100%;
  background: rgba(0,0,0,1);
  position: fixed;
  top: 0;
  left: 0;
  border-bottom: solid 1px rgba(255,255,255,0.26);
  z-index: var(--mui-shape-headerNav-zIndex);
}

.headerLogo {
  height: 60px;
  margin: 7px auto 0 auto;
  img {
    height: 100%;
    width: auto;
  }
}

.openButton {
  fill: rgba(255,255,255,0.35);
  height: calc(var(--mui-shape-mobileNav-height) - 20px);
  margin-top: 10px;
  width: auto;
  cursor: pointer;
  justify-self: center;
  margin-right: 15%;
}

.mobileNavBody {
  position: fixed;
  width: 100%;
  height: calc(100% - var(--mui-shape-mobileNav-height));
  top: var(--mui-shape-mobileNav-height);
  background: var(--mui-palette-mobileNavBodyBG);
  z-index: var(--mui-shape-mobileNav-zIndex);
  overflow-y: auto;
  padding-top: 20px;
  // @include for-breakpoint('md') {
  //   -ms-overflow-style: none;  /* IE and Edge */
  //   scrollbar-width: none;  /* Firefox */
  //   &::-webkit-scrollbar {
  //     display: none;
  //   }
  // }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    
    // overflow-x: hidden;
  }
}

.mobileNavBodyClosed {
  composes: mobileNavBody;
  display: none;
}

.mobileNavItem {
  width: 100%;
  text-align: right;
  position: relative;
  margin-bottom: 25px;
  &:nth-child(4) {
    margin-bottom: 0;
  }
}

.mobileNavItemTopStrip {
  background:var(--mui-palette-mobileNavItemBG);
  color: #fff;
  height: var(--mui-shape-mobileNav-itemHeight);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% - 4px);
  transition: width 100ms;
  position: relative;
  > div:nth-of-type(1) {
    height: 100%;
    width: 50px;
    position: absolute;
    right: 0;
    opacity: 0.19;
    background: linear-gradient(-90deg, var(--base-color) 0%, rgba(0, 0, 0, 0) 100%);
  }
  > div:nth-of-type(2) {
    width: 0%;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: width 250ms;
    > div:nth-child(1) {
      width: 100%;
      height: 25px;
      opacity: 0.2;
      background: linear-gradient(0deg, var(--base-color) 0%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      bottom: 3px;
      right: 0;
    }
    > div:nth-child(2) {
      width: 100%;
      height: 3px;
      background: var(--base-color);
      z-index: 999;
      position: absolute;
      bottom: 1px;
      right: 0;
    }
  }
}


.mobileNavItemTopStripActive{
  composes: mobileNavItemTopStrip;
  width: calc(100% - 12px);
  > div:nth-of-type(1) {
    width: 75px;
  }
  > div:nth-of-type(2) {
    width: 100%;
  }
}

.mobileNavItemColorChip {
  background:var(--base-color);
  height: calc(var(--mui-shape-mobileNav-itemHeight) - 2px);
  position: absolute;
  top: 1px;
  z-index: -1;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
}

.mobileNavItemChipHighlight {
  width: 50px;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 100%);
  mix-blend-mode:luminosity;
}

.mobileSubNav{
  display: none;
  margin-top: 25px !important;
}
.mobileSubNavActive{
  composes: mobileSubNav;
  display: block;
  > li {
    animation: mobileNavSubItemFadeIn cubic-bezier(0.7, 0, 0.84, 0) 150ms forwards;
    --base-animation-delay: 90ms;
    animation-delay: var(--base-animation-delay);
    &:nth-of-type(2) {
      animation-delay: calc(var(--base-animation-delay) * 2);
    }
    &:nth-of-type(3) {
      animation-delay: calc(var(--base-animation-delay) * 3);
    }
    &:nth-of-type(4) {
      animation-delay: calc(var(--base-animation-delay) * 4);
    }
    &:nth-of-type(5) {
      animation-delay: calc(var(--base-animation-delay) * 5);
    }
    &:nth-of-type(6) {
      animation-delay: calc(var(--base-animation-delay) * 6);
    }
    &:nth-of-type(7) {
      animation-delay: calc(var(--base-animation-delay) * 7);
    }
    &:nth-of-type(8) {
      animation-delay: calc(var(--base-animation-delay) * 8);
    }
    &:nth-of-type(9) {
      animation-delay: calc(var(--base-animation-delay) * 9);
    }
    &:nth-of-type(10) {
      animation-delay: calc(var(--base-animation-delay) * 10);
    }
  }
}
.mobileSubNavItem{
  background: var(--mui-palette-mobileNavSubItemBG);
  height: var(--mui-shape-mobileNav-subItemHeight);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 20px;
  margin-bottom: 20px;
  border-right: solid 2px var(--base-color); 
  box-sizing: border-box;
  opacity: 0;
  a {
    text-decoration: none;
  }
  
}

@keyframes mobileNavSubItemFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.userNavPad {
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17px 0;
    svg {
      fill: var(--mui-palette-Obscura-SapphireSkies);
      flex: 0 0 50px;
      width: 50px;
      height: auto;
      position: relative;
      top: -4px;
      margin-left:  0;
      margin-right: 1.5em;
    }
  }
  > ul {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,33.3%);
    background: rgba(0,0,0,0.2);
    > li {
      display: grid;
      cursor: pointer;
      padding: 20px 20px 0 20px;
      aspect-ratio: 1;
      justify-content: center;
      height: 100%;
      width: 100%;
      grid-template-rows: 80px 1fr;
      border: solid 1px rgba(255,255,255,0.04);
      > svg, img {
        width: 75%;
        height: auto;
        fill: var(--mui-palette-Obscura-Meaning);
        margin: 0 auto;
        align-self: center;
        justify-self: center;
        // background: #000;
        border-radius: 10px;
        padding: 10px;
      }
      &:nth-child(2) { // badge
        > svg {
          width: 86%;
          position: relative;
          top: 7%;
        }
      }
      &:nth-child(3) { // orders & reservations
        > svg {
          width: 70%;
        }
      }
      &:nth-child(4) { // history & receipts
        > svg {
          width: 92%;
          path {
            stroke: #fff;
          }
        }
      }
      &:nth-child(4) { // history & receipts
        > svg {
          width: 80%;
        }
      }
      &:nth-child(6) { // history & receipts
        > img {
          padding-bottom: 16px;
          padding-top: 16px;
        }
      }
    }
  }
}