@import '../../mixins.scss';
.GreebleModal{
  width: 400px;
  color: #fff;
  display: inline-flex;
  flex-direction: column;
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate3d(-50%,0,0);
  z-index: 2;
  background: rgba(0,0,0,0.5);
  a {
    cursor: pointer;
  }
  @include for-breakpoint('xs') {
    width: 330px;
    transform: translate3d(-46.3%, 0, 0);
  }
}

.headerBox {
  border: solid 2px #333;
  position: relative;
  padding: 10px;
  padding-right: 3em;
  height: 60px;
  > button {
    background: #000;
    border-radius: 100%;
    border: solid 1px #333;
    width: 50px;
    height: 50px;
    position: absolute;
    top: -25px;
    right: -25px;
    transition: background-color 400ms;
    &:hover {
      background: #fff;
    }
    cursor: pointer;
    > img {
      width: 80%;
      height: auto;
    }
  }
  >span {
    --box-size: 7px;
    background: var(--mui-palette-UltraPlush-magenta);
    width: var(--box-size);
    height: var(--box-size);
    transform: rotate(45deg);
    position: absolute;
    &:first-of-type {
      top: calc(var(--box-size) / -2);
      left: calc(var(--box-size) / -2);
    }
    &:nth-of-type(2) {
      bottom: calc(var(--box-size) / -2);
      right: calc(var(--box-size) / -2);
    }
    &:nth-of-type(3) {
      bottom: calc(var(--box-size) / -2);
      left: calc(var(--box-size) / -2);
    }
    &:nth-of-type(4) {
      top: calc(var(--box-size) / -2);
      right: calc(var(--box-size) / -2);
    }
  }
}


.mainBody {
  position: relative;
  padding: 25px 25px;
  min-width: 100%;
  margin-bottom: 10px;
  &:before {
    content:"";
    height: calc(100% - 10px);
    width: 25px;
    border: solid 4px rgba(0,174,239,0.3);
    border-radius: 10%;
    border-right: none;
    position: absolute;
    left: 5px;
    top: 10px;
  }
  &:after {
    content:"";
    height: calc(100% - 10px);
    width: 25px;
    border: solid 4px rgba(0,174,239,0.3);
    border-radius: 10%;
    border-left: none;
    position: absolute;
    right: 25px;
    top: 10px;
  }
}

.secondaryBody {
  width: 90%;
  padding: 10px 15px 15px 20px;
  font-size: 11px;
  margin: -10px auto 0 auto;
  z-index: 9;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px 0 20px;
  position: relative;
  > button {
    width: 100%;
    background: none;
    color: #fff;
    border: solid 2px #333;
    position: relative;
    padding: 10px;
    // margin-bottom: 15px;
    text-transform: capitalize;
    cursor: pointer;
    >span {
      --box-size: 7px;
      background: var(--mui-palette-UltraPlush-cyan);
      width: var(--box-size);
      height: var(--box-size);
      transform: rotate(45deg);
      position: absolute;
      &:first-of-type {
        top: calc(var(--box-size) / -2);
        left: calc(var(--box-size) / -2);
      }
      &:nth-of-type(2) {
        bottom: calc(var(--box-size) / -2);
        right: calc(var(--box-size) / -2);
      }
      &:nth-of-type(3) {
        bottom: calc(var(--box-size) / -2);
        left: calc(var(--box-size) / -2);
      }
      &:nth-of-type(4) {
        top: calc(var(--box-size) / -2);
        right: calc(var(--box-size) / -2);
      }
    }
    &:hover {
      background: var(--mui-palette-Obscura-Meaning);
      > p {
        color: var(--mui-palette-Obscura-Void);
      }
    }
  }
}

.postActionButtonContent{
  width: 100%;
  padding: 0 15px 0 20px;
  margin-left: -20px;
  position: absolute;
  text-align: center;
  white-space: wrap;
  font-size: 0.80rem;
  top: 115%;
  > p {
    margin: 0;
    a {
      color: var(--mui-palette-UltraPlush-cyan);
    }
  }
}

.RSideGreebles {
  position: absolute;
  // border: solid 1px rgba(255,0,0,0.5);
  width: 27px;
  top: 60px;
  right: -12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  > span:nth-of-type(1) {
    display: block;
    width: 15px;
    height: 15px;
    border: solid 1px var(--mui-palette-UltraPlush-cyan);
    border-radius: 100%;
    box-shadow: 0px 0px 15px 0px var(--mui-palette-UltraPlush-cyan), 0px 0px 20px 0px rgba(0, 175, 239, 0.3)
  }
  > span:nth-of-type(2) {
    width: 7px;
    height: 76px;
    margin-top: 15px;
    background: var(--mui-palette-UltraPlush-magenta);
    box-shadow: 0px 0px 13px 0px var(--mui-palette-UltraPlush-magenta),0px 0px 173px 0px var(--mui-palette-UltraPlush-magenta);
  }
  > span:nth-of-type(3) {
    text-shadow: 0px 0px 10px var(--mui-palette-UltraPlush-cyan),0px 0px 10px var(--mui-palette-UltraPlush-cyan);
  }
}

.LSideGreebles {
  position: absolute;
  // border: solid 1px rgba(255,0,0,0.5);
  height: 73%;
  width: 50px;
  top: -5px;
  left: -34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > span:first-of-type {
    --box-size: 7px;
    background: var(--mui-palette-UltraPlush-cyan);
    width: var(--box-size);
    height: var(--box-size);
    transform: rotate(45deg);
    margin-right: 20px;
  }
  > span:nth-of-type(2) {
    width: 7px;
    height: 65px;
    margin-top: 15px;
    background: var(--mui-palette-UltraPlush-magenta);
    box-shadow: 0px 0px 13px 0px var(--mui-palette-UltraPlush-magenta),0px 0px 173px 0px var(--mui-palette-UltraPlush-magenta);
    margin-right: 20px;
  }
  > div {
    margin-left: -30px;
    margin-top: 50px;
    background-color: rgba(0,0,0,0.3);
    > span {
      display: block;
      background: var(--mui-palette-UltraPlush-magenta);
      opacity: 0.7;
      width: 23px;
      height: 6px;
      margin-bottom: 9px;
      box-shadow: 0px 0px 13px 0px var(--mui-palette-UltraPlush-magenta),0px 0px 173px 0px var(--mui-palette-UltraPlush-magenta);
    }
  }
  > svg:nth-of-type(1) {
    height: 100%;
    position: absolute;
    top: 8px;
    left: 14px;
    z-index: -1;
  }
  > svg:nth-of-type(2) {
    width: 40%;
    position: absolute;
    bottom: -5%;
    right: 45%;
    opacity: 0.6;
    rotate: 180deg;
    filter: drop-shadow(0px 2px 7px var(--mui-palette-UltraPlush-cyan));
  }
  > span:nth-of-type(3) {
    --box-size: 7px;
    background: var(--mui-palette-UltraPlush-cyan);
    width: var(--box-size);
    height: var(--box-size);
    transform: rotate(45deg);
    position: absolute;
    bottom: -10px;
    right: 22%;
  }
}