@import '../../mixins.scss';
.UserNavDrawer {
  width: 0;
  overflow: hidden;
  transition: width 250ms;
  background: var(--mui-palette-userNavDrawerBG);
  box-shadow: inset 35px 35px 34px -30px #000000;
  z-index: 2;
  position: relative;
  height: calc(100lvh - var(--mui-shape-headerNav-height-L));
  overflow-x: hidden;
  overflow-y: auto;
  > ul {
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 1010px;
    position: relative;
    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      border-bottom: solid 1px rgba(255,255,255,0.08);
      padding: 20px 2em 20px 0;
      > svg, img {
        flex: 0 0 60px;
        width: 60px;
        height: auto;
        fill: var(--mui-palette-Obscura-Meaning);
        margin-left: 2em;
        margin-right: 2em;
      }
      &:first-child { // notifs
        padding: 10px 0;
        justify-content: center;
        svg {
          fill: var(--mui-palette-Obscura-SapphireSkies);
          flex: 0 0 50px;
          width: 50px;
          height: auto;
          position: relative;
          top: -4px;
          margin-left:  0;
          margin-right: 0.5em;
        }
        span {
          text-align: center;
          font-size: 1.2em;
        }
      }
      &:nth-child(5) { // receipt
        svg {
          path {
            stroke: var(--mui-palette-Obscura-Meaning);
          }
        }
      }
      &:nth-child(10) { // logout
        // margin-top: 15px;
        border-top: solid 1px rgba(255,255,255,0.08);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        svg {
          transform: scaleX(-1);
          path {
            stroke: var(--mui-palette-Obscura-Meaning);
          }
        }
        // @media(max-width: 2027px) {
        //   // border: solid 1px #f00;
        //   margin-top: 4px;
        // }
      }
    }
  }

  &[drawerOpen] {
    width: var(--mui-shape-basePageTemplate-userNavDrawerWidth-ultrawide);
    > ul {
      display: block;
    }
    @include for-breakpoint('hd') {
      width: var(--mui-shape-basePageTemplate-userNavDrawerWidth-hd);
    }
    @include for-breakpoint('xl') {
      width: var(--mui-shape-basePageTemplate-userNavDrawerWidth-xl);
    }
    @include for-breakpoint('lg') {
      width: var(--mui-shape-basePageTemplate-userNavDrawerWidth-lg);
    }
    @include for-breakpoint('md') {
      width: var(--mui-shape-basePageTemplate-userNavDrawerWidth-md);
    }
  }
}