.TeaserVid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  > div {
    z-index: 2;
  }
  video {
    width: 100vw;
    height: auto;
    background: #000;
    @media screen and (max-aspect-ratio: 16/9) {
      height: 100vh;
      width: auto;      
    }
  }
}

.TeaserVidOverlay {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.7);
};
