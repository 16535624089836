.GoodyModal {
  // background: #000;
  min-width: 250px;
  min-height: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  // padding: 10px;
  outline: none;
}

.FoundRabbitGoodyTemplate{
  display: grid;
  grid-template-rows: repeat(5,auto);
  grid-template-columns: auto;
  padding: 20px;
  background: #000;
  svg {
    width: 200px;
    height: auto;
    fill: #fff;
    margin: 0 auto;
  }

}