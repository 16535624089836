@use "sass:color";

.BigToutButton {
  border: solid 1px var(--base-color);
  min-height: 100px;
  min-width: 100px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px 0;
  z-index: 2;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-width 500ms, margin-top 500ms, background-color 100ms;
  >h1, >h2 {
    transition: color 500ms;
  }
  &:hover {
    border-width: 2px;
    margin-top: -1px;
  }
  &:active{
    background: var(--base-color);
    >h1, >h2 {
      color: #000 !important;
    }
    
  }
  &::after{
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    opacity: 0.35;
    animation: shadowMotion1 5s infinite forwards linear;
  }

}

@keyframes shadowMotion1 {
  0% {box-shadow: 10px 10px 37px 2px var(--base-color);}
  26% {box-shadow: -10px 10px 37px 2px var(--base-color);}
  49.5% {box-shadow: -10px -10px 37px 2px var(--base-color);}
  76% {box-shadow: 10px -10px 37px 2px var(--base-color);}
  100% {box-shadow: 10px 10px 37px 2px var(--base-color);}
}