
@import '../../mixins.scss';

.homeGrid {
  box-sizing: border-box !important;
  padding: 0 50px;
  margin-top: 50px !important;
  > div {
    padding-top: 0 !important;
  }
  > div:first-of-type {
    @include for-breakpoint('md') {
      padding-bottom: 20px;
    }
  }
}