@import '../../../mixins.scss';
.PasswordBox {
  border: solid 1px rgba(255,255,255,0.5);
  border-radius: 30px 0 30px 0;
  background: rgba(0,0,0,0.7);
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25vh auto 0 auto;
  width: fit-content;
  position: relative;
  z-index: 1;
  input {
    font-size: 1.7rem;
    padding: 0.6rem 1.1rem;
  }
  @include for-breakpoint('md') {
    width: 90%;
    padding: 2em 1em;
    svg {
      margin-right: 1em;
    }
    input {
      width:100%;
      font-size: 1.2em;
    }
  }
}

.IncorrectPasswordBox{
  composes: PasswordBox;
  border: solid 2px rgba(255,0,0,0.7);
  animation: doShake 500ms;
}

@keyframes doShake {
  0% { transform: translate3d(0,0,0) }
  25% { transform: translate3d(5px,-5px,0) }
  50% { transform: translate3d(-5px,3px,0) }
  75% { transform: translate3d(5px,-1px,0) }
  100% { transform: translate3d(0,0,0) }
 }