@import '../../mixins.scss';
.BasePageTemplate {
  margin-top: var(--mui-shape-headerNav-height-L);
  // background: #000;
  min-height: calc(100lvh - var(--mui-shape-headerNav-height-L));
  display: flex;
  flex-direction: column;
  @include for-breakpoint('md'){
    margin-top: var(--mui-shape-headerNav-height-L); 
  }
}

.BasePageTemplateScrollLocked {
  composes: BasePageTemplate;
  height: 100%;
  width: 100%;
  overflow: hidden;
}


.contentWrapper {
  display: flex;
  flex-direction: row;
  min-height: calc(100lvh - var(--mui-shape-headerNav-height-L));
  > div:first-child {
    display: block;
    width: 100%;
    z-index: var(--mui-shape-basePageTemplate-baseContentZIndex);
  }
}