html, body {
  margin: 0;
  background: #000;
}

* {
  position: relative;
  z-index: 1;
}

