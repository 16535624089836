@import '../../mixins.scss';
.LogoStrip {
  width: 100%;
  max-height: 220px;
  background: rgba(0,0,0,0.7);
  margin-top: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  padding: 20px 0;
  border: solid 0.5px rgba(255,255,255,0.4);
  border-left: none;
  border-right: none;
  position: relative;
  img {
    aspect-ratio: 606/191;
    max-width: 500px;
    width: 50%;
    margin: 10px 0;
    @include for-breakpoint('md') {
      width: 65%;
    }
  }
}